import styled from 'styled-components'

export const ErrorContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 90;
  background-color: var(--neutral-theme);
  overflow: hidden;
  height: 100vh;
`
