import { PaymentMethod } from '../../module/family/pages/payment/types'

export type PaymentMethodState = {
  paymentMethod: PaymentMethodObject
} & PaymentMethodAction

export interface PaymentMethodObject {
  selectedPaymentMethod: PaymentMethod
}

export interface PaymentMethodAction {
  setSelectedPaymentMethod: (selectedPaymentMethod: PaymentMethod) => void
}

export const INITIAL_STATE: PaymentMethodObject = {
  selectedPaymentMethod: {
    icon: 'Wallet',
    label: 'Débito Inter',
    selected: false,
  },
}
