import { create } from 'zustand'

import { NavbarState } from './navbar/types'
import createNavbarSlice from './navbar'
import { DarkModeState } from './darkMode/types'
import createDarkModeSlice from './darkMode'
import { PageTrasitionState } from './pageTransitionAnimation/type'
import createPageTransitionSlice from './pageTransitionAnimation'
import { ErrorState } from './error/types'
import createErrorSlice from './error'
import { LoadingState } from './loading/types'
import createLoadingSlice from './loading'
import { UserInfoState } from './user/types'
import createUserInfoSlice from './user'
import { BottomSheetState } from './bottomSheet/types'
import createBottomsheetSlice from './bottomSheet'
import { PaymentMethodState } from './paymentMethod/types'
import createPaymentMethodSlice from './paymentMethod'
import { IneligibilityState } from './ineligibility/types'
import createIneligibilityResponsedSlice from './ineligibility'
import createReceiptSlice from './receipt'
import { ReceiptState } from './receipt/types'

type RootState = NavbarState &
  DarkModeState &
  PageTrasitionState &
  ErrorState &
  UserInfoState &
  LoadingState &
  BottomSheetState &
  PaymentMethodState &
  IneligibilityState &
  ReceiptState

const useBoundState = create<RootState>()((...a) => ({
  ...createNavbarSlice(...a),
  ...createDarkModeSlice(...a),
  ...createPageTransitionSlice(...a),
  ...createErrorSlice(...a),
  ...createUserInfoSlice(...a),
  ...createLoadingSlice(...a),
  ...createBottomsheetSlice(...a),
  ...createPaymentMethodSlice(...a),
  ...createIneligibilityResponsedSlice(...a),
  ...createReceiptSlice(...a),
}))

export default useBoundState
