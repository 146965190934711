import React from 'react'

import { Redirect, Route } from 'wouter'

import { RoutesInfo } from '../../../routes/types'

const Home = React.lazy(() => import('../pages/home'))
const Faq = React.lazy(() => import('../pages/faq'))
const Receipt = React.lazy(() => import('../pages/receipt'))
const KnowMore = React.lazy(() => import('../pages/knowMore'))
const Payment = React.lazy(() => import('../pages/payment'))
const Ineligibility = React.lazy(() => import('../pages/ineligibility'))
const Pdf = React.lazy(() => import('../pages/pdf'))

export const FamilyRoutesInfo: Record<string, RoutesInfo> = {
  homeFamily: { path: '/family/home' },
  paymentFamily: { path: '/family/payment' },
  faqFamily: { path: '/family/faq' },
  receiptFamily: { path: '/family/receipt' },
  knowMoreFamily: { path: '/family/know-more' },
  ineligibilityFamily: { path: '/family/ineligibility' },
  pdfFamily: { path: '/family/pdf' },
}

const RedirectHome = () => <Redirect to={FamilyRoutesInfo.homeFamily.path} />

export const FamilyModule = () => (
  <>
    <Route path="/" component={RedirectHome} />
    <Route path={FamilyRoutesInfo.homeFamily.path} component={Home} />
    <Route path={FamilyRoutesInfo.faqFamily.path} component={Faq} />
    <Route path={FamilyRoutesInfo.knowMoreFamily.path} component={KnowMore} />
    <Route path={FamilyRoutesInfo.paymentFamily.path} component={Payment} />
    <Route path={FamilyRoutesInfo.receiptFamily.path} component={Receipt} />
    <Route path={FamilyRoutesInfo.ineligibilityFamily.path} component={Ineligibility} />
    <Route path={FamilyRoutesInfo.pdfFamily.path} component={Pdf} />
  </>
)
