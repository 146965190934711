import { IWbUserInfo } from '@interco/inter-webview-bridge'

import { MockService } from '../mockService'
import { MultiplatformHttpService } from '../multiplatformHttpService'
import { ServiceResponse } from '../multiplatformHttpService/types'
/**
 * This service should only be called on browser
 */
class UserInfoMockService {
  static async requestUserInfo(account?: string): Promise<IWbUserInfo> {
    const path = account ? `app-bridge/user/${account}` : 'app-bridge/user'
    try {
      const response: ServiceResponse<IWbUserInfo> =
        await MultiplatformHttpService.doExecute<IWbUserInfo>({
          urlPrefix: MockService.baseUrl,
          endpoint: {
            browser: path,
            bridge: path,
          },
          headers: {
            browser: { 'x-api-key': MockService.apiKey },
          },
          method: 'GET',
        })

      return response.data
    } catch (error) {
      throw MockService.handleMockServiceErrors(
        error,
        'UserInfoMockService.handleMockServiceErrors',
      )
    }
  }
}
export default UserInfoMockService
