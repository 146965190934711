import { IWbHttpData } from '@interco/inter-webview-bridge'

import { MultiplatformHttpRequestConfig } from '../../service/multiplatformHttpService/types'
import { MonitoringService } from '../../service/monitoringService'

export function logCallByBridgeRequest(request: MultiplatformHttpRequestConfig): void {
  MonitoringService.log('BaseService.callByBridge.request', request)
}

export function logCallByBridgeResponse(response: IWbHttpData): void {
  MonitoringService.log('BaseService.callByBridge.response', response)
}
