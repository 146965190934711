import { GENERAL_SERVICE_ERROR_MESSAGE, ServiceError } from '../../exceptions'
import { MockService } from '../mockService'
import { CredentialType, BrowserHttpOptions, OAuthType } from './types'
import { EnvVariableService } from '../envService'
import { EnvVariableKeys } from '../envService/types'

const IS_NOT_RUNNING_PROD = !EnvVariableService.getVariableAsBoolean(
  EnvVariableKeys.INTER_ENV,
  'production',
)

const API_BASE_URL = EnvVariableService.getVariable(EnvVariableKeys.API_BASE_URL)

export class BrowserHttpService {
  private static _baseURL = API_BASE_URL

  private static _headers: Record<string, string | number | boolean> = {}

  private static hasValidToken(): boolean {
    const token = BrowserHttpService._headers['x-api-token']

    if (!token) {
      return false
    }

    const payload = token.toString().split('.')[1]
    const payloadParsed = JSON.parse(atob(payload))
    const tokenTTL = parseInt(payloadParsed.exp, 10) * 1000

    return tokenTTL > Date.now()
  }

  private static async authDev(): Promise<void> {
    const credentialResponse: Response = await fetch(
      `${MockService.baseUrl}/backend-oauth/credentials`,
      {
        method: 'GET',
        headers: {
          'x-api-key': MockService.apiKey,
        },
      },
    )

    if (!credentialResponse.ok) {
      throw new ServiceError(
        false,
        await credentialResponse.json(),
        GENERAL_SERVICE_ERROR_MESSAGE,
        credentialResponse.status,
      )
    }

    const credential = (await credentialResponse.json()) as CredentialType
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Basic ${credential.authorization}`,
    }

    const response = await fetch(`${API_BASE_URL}/oauth/token?grant_type=client_credentials`, {
      method: 'POST',
      headers,
    })

    if (!response.ok) {
      throw new ServiceError(
        false,
        await response.json(),
        GENERAL_SERVICE_ERROR_MESSAGE,
        response.status,
      )
    }

    const token = (await response.json()) as OAuthType
    // family no needs access token to do request nedds to review that
    BrowserHttpService._headers['x-api-token'] = token.access_token
  }

  /**
   * The method witch prepare the fetch service to make the @method request
   */
  static async prepareApi(baseURL: string = API_BASE_URL): Promise<void> {
    BrowserHttpService._headers['Content-Type'] = 'application/json'

    /**
     * If the @var MockService.shouldMock is true and @const IS_NOT_RUNNING_PROD
     * the fetch api should communicate with the mock server.
     */
    if (MockService.shouldMock && IS_NOT_RUNNING_PROD) {
      BrowserHttpService._baseURL = MockService.baseUrl
    }

    if (!BrowserHttpService.hasValidToken() && !MockService.shouldMock) {
      await BrowserHttpService.authDev()
    }
    // This assignment must be after BrowserHttpService.authDev()
    BrowserHttpService._baseURL = baseURL
  }

  static async request(
    endpoint: string,
    options: BrowserHttpOptions['options'],
  ): Promise<Response> {
    await BrowserHttpService.prepareApi(options.baseURL)

    const url = `${BrowserHttpService._baseURL}/${endpoint}${
      options.params ? `?${new URLSearchParams({ ...options.params }).toString()}` : ''
    }`

    return fetch(url, {
      ...options,
      headers: {
        ...BrowserHttpService._headers,
        ...options.headers,
      } as HeadersInit,
    })
  }
}
