import { StateCreator } from 'zustand'

import { PaymentMethodState, INITIAL_STATE } from './types'

const createPaymentMethodSlice: StateCreator<PaymentMethodState, [], [], PaymentMethodState> = (
  set,
) => ({
  paymentMethod: INITIAL_STATE,
  setSelectedPaymentMethod: (selectedPaymentMethod) =>
    set(() => ({ paymentMethod: { selectedPaymentMethod } })),
})
export default createPaymentMethodSlice
