import styled from 'styled-components'

import { DivStyledProps, FlexStyledProps, FontSizeREM, TextStyledProps } from '../types'

export const P = styled.p<TextStyledProps>`
  font-size: ${(props) => props.fontSize || FontSizeREM.PX16};
  line-height: ${(props) => props.lineHeight || FontSizeREM.PX18};
  font-weight: ${(props) => props.fontWeight || `600`};
  font-family: ${(props) => props.fontFamily || 'Inter'};
  text-align: ${(props) => props.textAlign || 'unset'};
`

export const PSmall = styled(P)`
  max-width: ${(props) => props.maxWidth || 'unset'};
  font-size: ${(props) => props.fontSize || FontSizeREM.PX14};
  line-height: ${(props) => props.lineHeight || FontSizeREM.PX18};
  font-weight: ${(props) => props.fontWeight || `400`};
`

export const PSmallBold = styled(PSmall)`
  font-weight: 700;
`

export const PBlack = styled(P)`
  color: ${(props) => (props.disabled ? 'var(--gray300)' : 'var(--gray500)')};
`

export const PSmallGray = styled(PSmall)`
  color: ${(props) => (props.disabled ? 'var(--gray200)' : 'var(--gray400)')};
`

export const Small = styled.small<TextStyledProps>`
  display: block;
  font-weight: ${(props) => (props.bold ? '700' : `400`)};
  margin: ${(props) => props.margin || 'unset'};
`

export const Span = styled.span<{
  color?: string
  lineHeight?: string
  fontSize?: string
  fontWeight?: number
}>`
  color: ${(props) => props.color || 'var(--gray500)'};
  font-size: ${(props) => props.fontSize || FontSizeREM.PX16};
  line-height: ${(props) => props.lineHeight || FontSizeREM.PX16};
  font-weight: ${(props) => props.fontWeight || `400`};
`

export const H1 = styled.h1<TextStyledProps>`
  margin: ${(props) => props.margin || '0 0 12px'};
`

export const H3 = styled.h3<TextStyledProps>`
  margin: ${(props) => props.margin || '0 0 8px'};
`

export const Div = styled.div<DivStyledProps>`
  position: ${(props) => props.position || 'unset'};
  top: ${(props) => props.top || 'unset'};
  left: ${(props) => props.left || 'unset'};
  bottom: ${(props) => props.bottom || 'unset'};
  right: ${(props) => props.right || 'unset'};
  height: ${(props) => props.height || 'unset'};
  width: ${(props) => props.width || 'unset'};
  margin: ${(props) => props.margin || '0'};
  background-color: ${(props) => props.backgroundColor || 'unset'};
  transform: ${(props) => props.transform || 'unset'};
  overflow-y: ${(props) => props.overflowY || 'unset'};
  overflow-x: ${(props) => props.overflowX || 'unset'};
  z-index: ${(props) => props.zIndex || 'unset'};
  border-radius: ${(props) => props.borderRadius || 'unset'};
`

export const EditButton = styled.span<TextStyledProps>`
  position: ${(props) => props.position || 'unset'};
  top: ${(props) => props.top || 'unset'};
  left: ${(props) => props.left || 'unset'};
  bottom: ${(props) => props.bottom || 'unset'};
  right: ${(props) => props.right || 'unset'};
  color: ${(props) => props.color || 'var(--primary500)'};
  font-size: ${(props) => props.fontSize || FontSizeREM.PX16};
  line-height: ${(props) => props.lineHeight || FontSizeREM.PX18};
  font-weight: ${(props) => props.fontWeight || `700`};
`

export const Flex = styled.div<FlexStyledProps>`
  display: flex;
  flex-direction: ${(props) => props.flexDirection || 'column'};
  justify-content: ${(props) => props.justifyContent || 'unset'};
  align-items: ${(props) => props.alignItems || 'unset'};
  height: ${(props) => props.height || 'unset'};
  width: ${(props) => props.width || 'unset'};
  margin: ${(props) => props.margin || 'unset'};
  overflow-y: ${(props) => props.overflowY || 'unset'};
  overflow-x: ${(props) => props.overflowX || 'unset'};
  background-color: ${(props) => props.backgroundColor || 'unset'};
  color: ${(props) => props.color || 'unset'};
  padding: ${(props) => props.padding || 'unset'};
  border-radius: ${(props) => props.borderRadius || 'unset'};
  gap: ${(props) => props.gap || 'unset'};
`

export const ErrorMessage = styled.div`
  height: 1rem;
  font-size: 0.75rem;
  line-height: 1rem;
  color: 'var(--error500)';
`

export const InfoMessage = styled.div<{ color?: string }>`
  height: 1rem;
  font-size: 0.75rem;
  line-height: 1rem;
  color: ${(props) => props.color || 'var(--gray400)'};
`
