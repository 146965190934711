import React, { useEffect } from 'react'

import useBoundState from '../../../../store'

const ErrorObserver: React.FC = () => {
  const { hasError, errorInstance } = useBoundState((state) => state.error)

  useEffect(() => {
    if (hasError) {
      throw errorInstance
    }
  }, [errorInstance, hasError])

  return <div />
}

export default ErrorObserver
