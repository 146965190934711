import React, { useEffect } from 'react'

import { Theme, initTheme } from '@interco/inter-ui'

import { FamilyModule } from './family/routes'

const ModulesRoutes = () => {
  useEffect(() => {
    initTheme(Theme.PF)
  }, [])

  return (
    <>
      <FamilyModule />
    </>
  )
}

export default ModulesRoutes
