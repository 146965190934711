import { unstable_batchedUpdates } from 'react-dom'

import { WouterLocation } from './types'
import { PageDirections } from '../../../store/pageTransitionAnimation/type'
import useBoundState from '../../../store'

export class WouterService {
  private static _root = { pathname: '/' }

  private static _setLocation: (pathname: string) => void

  private static _history: Array<WouterLocation> = [WouterService._root]

  static resetHistory(): void {
    WouterService._history = [WouterService._root]
  }

  static get history(): Array<WouterLocation> {
    return WouterService._history
  }

  private static setDirection(pageDirection: PageDirections): void {
    unstable_batchedUpdates(() => {
      useBoundState.getState().setPageTrasitionDirection(pageDirection)
    })
  }

  static navigate(pathname: string, direction = PageDirections.FORWARD): void {
    WouterService.setDirection(direction)
    if (!WouterService._setLocation) {
      throw new Error('WouterService: setLocation should be defined!')
    }

    const lastHistoryItem = WouterService._history[WouterService._history.length - 1]

    if (lastHistoryItem.pathname !== pathname) {
      const newLocation = {
        pathname,
      }
      WouterService._history.push(newLocation)
      WouterService._setLocation(pathname)
    }
  }

  static goBack(times = 1) {
    if (!WouterService._setLocation) {
      throw new Error('WouterService: setLocation should be defined!')
    }

    if (WouterService._history.length > 1) {
      // current path
      WouterService._history.pop()

      if (times >= WouterService._history.length) {
        WouterService.resetHistory()
        WouterService._setLocation(WouterService._root.pathname)
        return
      }

      let countTimes = 0
      let routeToGo = WouterService._root

      while (countTimes < times) {
        routeToGo = WouterService._history.pop() as WouterLocation
        countTimes += 1
      }
      WouterService.navigate(routeToGo.pathname, PageDirections.BACKWARD)
    }
  }

  static initWouter(setLocation: (pathname: string) => void) {
    WouterService._setLocation = setLocation
  }
}
