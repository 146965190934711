import InterWebviewBridge, {
  interWbAuth,
  interWbNavigate,
  interWbSession,
  IWbISafeResponse,
  IWbUserInfo,
  WbEnvironments,
  WbSessionActions,
} from '@interco/inter-webview-bridge'

import {
  logISafeRequest,
  logISafeResponse,
  logUserInfoResponse,
} from '../../loggers/bridgeServiceLogger'
import AppInfoMockService from '../appInfoMockService'
import ISafeTokenMockService from '../iSafeTokenMockService'
import { MockService } from '../mockService'
import { MonitoringService } from '../monitoringService'
import UserInfoMockService from '../userInfoMockService'
import { InfoBridge, IRequestISafe, IWbAppInfo } from './types'
import { EnvVariableKeys } from '../envService/types'
import { WouterService } from '../wouterService'
import { EnvVariableService } from '../envService'
import { FamilyRoutesInfo } from '../../../module/family/routes'

const IS_NOT_RUNNING_PROD = !EnvVariableService.getVariableAsBoolean(
  EnvVariableKeys.INTER_ENV,
  'production',
)

class BridgeService {
  public static interWb = InterWebviewBridge.getInstance()

  private static handleBackButton: (() => void) | undefined

  private static addUrlProtocol(url: string) {
    const HTTPS_PROTOCOL = 'https://'
    const HTTP_PROTOCOL = 'http://'
    if (url.startsWith(HTTPS_PROTOCOL) || url.startsWith(HTTP_PROTOCOL)) {
      return url
    }
    return HTTPS_PROTOCOL + url
  }

  static getEnvironment(): WbEnvironments {
    return this.interWb.getEnvironment()
  }

  static isBrowser(): boolean {
    return this.interWb.getEnvironment() === WbEnvironments.BROWSER
  }

  static enableRemoteBridgeDebugLog() {
    ;(this.interWb as unknown as InfoBridge).info = (
      action: string,
      evitId: string,
      message: string,
    ) => {
      console?.info(`InterWebviewBridge - ${action}(${evitId}): ${message}`)

      MonitoringService.log(
        'BridgeService.enableRemoteBridgeDebugLog',
        `InterWebviewBridge - ${action}(${evitId}): ${message}`,
      )
    }

    this.interWb.setDebug(true)
  }

  static async getUserInfo(): Promise<IWbUserInfo> {
    let userInfo

    if (BridgeService.isBrowser() && IS_NOT_RUNNING_PROD) {
      userInfo = await UserInfoMockService.requestUserInfo(MockService.account)
    } else {
      userInfo = await interWbSession.getUserInfo()
    }
    logUserInfoResponse(userInfo)
    return userInfo
  }

  static async getAppInfo(): Promise<IWbAppInfo> {
    let appInfo

    if (BridgeService.isBrowser() && IS_NOT_RUNNING_PROD) {
      appInfo = await AppInfoMockService.requestAppInfo()
    } else {
      appInfo = await interWbSession.getAppInfo()
    }
    return appInfo
  }

  static async getISafeToken(requestData: IRequestISafe): Promise<IWbISafeResponse> {
    logISafeRequest(requestData)

    let iSafeToken
    if (BridgeService.isBrowser() && IS_NOT_RUNNING_PROD) {
      iSafeToken = await ISafeTokenMockService.requestISafe(
        requestData.category,
        requestData.checkType,
        requestData.feature,
        requestData.value,
      )
    } else {
      iSafeToken = await interWbAuth.requestISafe(
        requestData.category,
        requestData.checkType,
        requestData.feature,
        requestData.value,
      )
    }

    logISafeResponse(iSafeToken)

    return iSafeToken
  }

  static async backToNative(): Promise<void> {
    if (this.isBrowser()) {
      WouterService.navigate(FamilyRoutesInfo.homeFamily.path)
    } else {
      await interWbNavigate.requestGoBack()
    }
  }

  static async openDeepLink(url: string): Promise<void> {
    if (!this.isBrowser()) {
      await interWbNavigate.openDeepLink(url)
    }
  }

  static async sharedContentPDF(url: string, title: string): Promise<void> {
    if (this.isBrowser()) {
      window.open(BridgeService.addUrlProtocol(url), '_blank')?.focus()
      return
    }
    try {
      await this.interWb.execute({
        action: 'sharedContentPDF',
        metadata: {
          url,
          title,
        },
      })
    } catch (e) {
      MonitoringService.noticeError(e as Error, {
        errorCodeRef: 'BridgeService.sharedContentPDF.sharedContentPDF',
      })
    }
  }

  static async openPdf(url: string, title: string): Promise<void> {
    if (this.isBrowser()) {
      window.open(BridgeService.addUrlProtocol(url), '_blank')?.focus()
      return
    }
    try {
      await interWbNavigate.openPdf(title, url)
    } catch (e) {
      MonitoringService.noticeError(e as Error, {
        errorCodeRef: 'BridgeService.openPdf.displayPdf',
      })
    }
  }

  static async sharedContentEmail(
    recipient: string,
    subject = 'Coloque seu assunto',
    htmlBody = 'Escreva sua mensagem',
  ): Promise<void> {
    if (this.isBrowser()) {
      const urlMailto = `mailto:${recipient}&subject=${subject}&body=${htmlBody}`
      window.open(urlMailto, '_blank')?.focus()
      return
    }

    try {
      await this.interWb.execute({
        action: 'sharedContentEmail',
        metadata: {
          subject,
          recipient,
          htmlBody,
        },
      })
    } catch (e) {
      MonitoringService.noticeError(e as Error, {
        errorCodeRef: 'BridgeService.sharedContentEmail.sharedContentEmail',
      })
    }
  }

  static async openBrowser(url: string): Promise<void> {
    if (this.isBrowser()) {
      window.open(BridgeService.addUrlProtocol(url), '_blank')?.focus()
      return
    }

    try {
      await this.interWb.execute({
        action: 'openBrowser',
        metadata: {
          url: BridgeService.addUrlProtocol(url),
        },
      })
    } catch (e) {
      MonitoringService.noticeError(e as Error, {
        errorCodeRef: 'BridgeService.openBrowserRequest.openBrowser',
      })
    }
  }

  static async goToAppHomeOrBackToNative(navigate: (pathname: string) => void): Promise<void> {
    if (this.isBrowser()) {
      navigate('/')
      return
    }

    if (this.interWb.getEnvironment() === WbEnvironments.ANDROID) {
      try {
        await this.interWb.execute({ action: 'navigateToHome' })
      } catch (e) {
        MonitoringService.noticeError(e as Error, {
          errorCodeRef: 'BridgeService.goToAppHomeOrBackToNative.navigateToHome',
        })
        await BridgeService.backToNative()
      }
    }

    if (this.interWb.getEnvironment() === WbEnvironments.IOS) {
      try {
        await this.interWb.execute({ action: 'navigateHome' })
      } catch (e) {
        MonitoringService.noticeError(e as Error, {
          errorCodeRef: 'BridgeService.goToAppHomeOrBackToNative.navigateHome',
        })
        await BridgeService.backToNative()
      }
    }
  }

  static requestAnalytics(
    name: string,
    params?: Record<string, string>,
    errorCodeRef?: string,
  ): void {
    if (!this.isBrowser()) {
      interWbSession.requestAnalytics(name, params).catch((e) => {
        MonitoringService.noticeError(e, {
          error: errorCodeRef ?? 'no code',
          requestAnalyticsName: name,
          requestAnalyticsParams: params ? JSON.stringify(params) : 'No params',
        })
      })
    }
  }

  static addBackButtonEvent(callback: () => void): void {
    if (this.handleBackButton) {
      this.interWb.removeWebviewEventListener(WbSessionActions.GO_BACK, this.handleBackButton)
    }

    this.handleBackButton = callback
    this.interWb
      .addWebviewEventListener(WbSessionActions.GO_BACK, this.handleBackButton)
      .then(() => {
        MonitoringService.log(
          'BridgeService.addBackButtonEvent',
          'Completou chamado ao BridgeService.addBackButtonEvent',
        )
      })
      .catch((e) => {
        MonitoringService.noticeError(e, {
          errorCodeRef: 'BridgeService.addBackButtonEvent.addWebviewEventListener',
        })
      })
  }

  static async callPhoneNumber(phoneNumber: string): Promise<void> {
    const SPACE_STRING_RGEX = /\s+/g
    const EMPTY_STRING = ''

    try {
      await this.interWb.execute({
        action: 'callPhoneNumber',
        metadata: {
          phoneNumber: phoneNumber.replace(SPACE_STRING_RGEX, EMPTY_STRING),
        },
      })
    } catch (e) {
      MonitoringService.noticeError(e as Error, {
        errorCodeRef: 'BridgeService.callPhoneNumber.callPhoneNumber',
      })
    }
  }

  static async editToolBar() {
    try {
      if (!this.isBrowser()) {
        interWbSession.editToolbar('', false)
      }
    } catch (e) {
      MonitoringService.noticeError(e as Error, {
        errorCodeRef: 'BridgeService.openPdf.displayPdf',
      })
    }
  }
}

export default BridgeService
