import React from 'react'

import { OverlayBlock } from './styles'
import useBoundState from '../../../store'

interface OverlayProps {
  className?: string
}

const Overlay = ({ className }: OverlayProps) => {
  const { isLoading } = useBoundState((state) => state.loading)
  return <OverlayBlock className={className} show={isLoading ? true : undefined} />
}

export default Overlay
