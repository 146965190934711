import React, { useCallback, useEffect } from 'react'

import { useErrorHandler } from 'react-error-boundary'

import { Div } from '../../styles/commons'
import { _try } from '../../utils/errorUtils'
import BridgeService from '../../service/bridgeService'
import useBoundState from '../../../store'
import { EnvVariableService } from '../../service/envService'
import { EnvVariableKeys } from '../../service/envService/types'
import { MockService } from '../../service/mockService'
import { MonitoringService } from '../../service/monitoringService'
import { AppInfoService } from '../../service/appInfoService'

const OnWebviewInit: React.FC = () => {
  const handleError = useErrorHandler()

  const setUserInfo = useBoundState((state) => state.setUserInfo)

  const setIsDarkMode = useBoundState((state) => state.setIsDarkMode)

  const isRemoteBridgeDebugLogEnable = EnvVariableService.getVariableAsBoolean(
    EnvVariableKeys.REMOTE_BRIDGE_DEBUG_LOG_ENABLED,
  )

  const getUserInfo = useCallback(
    () =>
      _try(
        async () => {
          const response = await BridgeService.getUserInfo()
          setUserInfo(response)
          await MonitoringService.setDefaultAttributes(response.account)
          if (isRemoteBridgeDebugLogEnable) {
            BridgeService.enableRemoteBridgeDebugLog()
          }
          if (!MockService.account) {
            MockService.account = response.account
            MockService.prepareMatchHeadersByUserAccount()
          }
          setIsDarkMode(AppInfoService.isDarkMode)
        },
        handleError,
        'OnWebviewInit.getUserInfo',
      ),
    [handleError, setUserInfo, isRemoteBridgeDebugLogEnable, setIsDarkMode],
  )

  useEffect(() => {
    getUserInfo()
  }, [])

  return <Div />
}

export default OnWebviewInit
