export enum EnvVariableKeys {
  INTER_ENV = 'REACT_APP_INTER_ENV',
  USE_REDIRECT_PREFIX = 'REACT_APP_USE_REDIRECT_PREFIX',
  API_BASE_URL = 'REACT_APP_API_BASE_URL',
  MOCK_SERVER_BASE_URL = 'REACT_APP_MOCK_SERVER_BASE_URL',
  LOCAL_LOG_ENABLED = 'REACT_APP_LOCAL_LOG_ENABLED',
  REMOTE_LOG_ENABLED = 'REACT_APP_REMOTE_LOG_ENABLED',
  REMOTE_BRIDGE_DEBUG_LOG_ENABLED = 'REACT_APP_REMOTE_BRIDGE_DEBUG_LOG_ENABLED',
  API_USER_INFO_V3 = 'REACT_APP_API_USER_INFO_V3',
  API_BRIDGE_BALANCE_V3 = 'REACT_APP_API_BRIDGE_BALANCE_V3',
  APP_API_FAMILY_HOME = 'REACT_APP_API_FAMILY_HOME',
  APP_API_BRIDGE_FAMILY_HOME = 'REACT_APP_API_BRIDGE_FAMILY_HOME',
  APP_API_FAMILY_RECEIPT = 'REACT_APP_API_FAMILY_RECEIPT',
  APP_API_BRIDGE_FAMILY_RECEIPT = 'REACT_APP_API_BRIDGE_FAMILY_RECEIPT',
  APP_API_FAMILY_PAYMENT = 'REACT_APP_API_FAMILY_PAYMENT',
  APP_API_BRIDGE_FAMILY_PAYMENT = 'REACT_APP_API_BRIDGE_FAMILY_PAYMENT',
  APP_API_FAMILY_FAQ = 'REACT_APP_API_FAMILY_FAQ',
  APP_API_BRIDGE_FAMILY_FAQ = 'REACT_APP_API_BRIDGE_FAMILY_FAQ',
  APP_API_FAMILY_KNOW_MORE = 'REACT_APP_API_FAMILY_KNOW_MORE',
  APP_API_BRIDGE_FAMILY_KNOW_MORE = 'REACT_APP_API_BRIDGE_FAMILY_KNOW_MORE',
}
