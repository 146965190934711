import { ReactNode } from 'react'

export type NavbarState = {
  navbar: NavbarObject
} & NavbarActions

export interface NavbarObject {
  title: string
  hideLeftIcon: boolean
  leftIcon?: ReactNode
  rightIcon?: ReactNode
}

export interface NavbarActions {
  setNavbarTitle: (title: string) => void
  setNavbarHideLeftIcon: (hideLeftIcon: boolean) => void
  setNavbarLeftIcon: (leftIcon: ReactNode) => void
  setNavbarRightIcon: (rightIcon: ReactNode) => void
  resetNavbar: () => void
}

export const INITIAL_STATE: NavbarObject = {
  title: '',
  hideLeftIcon: false,
}
