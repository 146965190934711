export enum FontSizeREM {
  PX10 = '0.625rem',
  PX11 = '0.688rem',
  PX12 = '0.75rem',
  PX13 = '0.813rem',
  PX14 = '0.875rem',
  PX15 = '0.938rem',
  PX16 = '1rem',
  PX17 = '1.063rem',
  PX18 = '1.125rem',
  PX19 = '1.188rem',
  PX20 = '1.25rem',
  PX21 = '1.313rem',
  PX22 = '1.375rem',
  PX23 = '1.438rem',
  PX24 = '1.5rem',
  PX25 = '1.563rem',
  PX26 = '1.625rem',
  PX27 = '1.688rem',
  PX28 = '1.75rem',
  PX29 = '1.813rem',
  PX30 = '1.875rem',
}

export interface FlexStyledProps {
  name?: string
  flexDirection?: string
  justifyContent?: string
  alignItems?: string
  height?: string
  width?: string
  overflowX?: string
  overflowY?: string
  margin?: string
  gap?: string
  backgroundColor?: string
  padding?: string
  borderRadius?: string
}

export interface TextStyledProps {
  color?: string
  lineHeight?: string
  fontSize?: string
  fontWeight?: number
  maxWidth?: string
  disabled?: boolean
  fontFamily?: string
  textAlign?: string
  margin?: string
  bold?: boolean
  position?: string
  top?: string
  left?: string
  bottom?: string
  right?: string
}

export interface DivStyledProps {
  margin?: string
  position?: string
  top?: string
  left?: string
  bottom?: string
  right?: string
  height?: string
  width?: string
  backgroundColor?: string
  transform?: string
  overflowY?: string
  overflowX?: string
  zIndex?: number
  borderRadius?: string
}
