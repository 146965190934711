import { unstable_batchedUpdates } from 'react-dom'

import { ServiceError, TrackableError } from '../../exceptions'
import useBoundState from '../../../store'
import { ErrorResponse } from '../../../store/error/types'

function withErrorCodeRef(originalError: unknown, errorCodeRef: string): TrackableError {
  let trackableError: TrackableError = new Error(JSON.stringify(originalError))
  trackableError.errorCodeRef = errorCodeRef

  if (originalError instanceof Error) {
    trackableError = originalError
    trackableError.errorCodeRef = errorCodeRef
    return trackableError
  }

  if (typeof originalError === 'string') {
    trackableError = new Error(originalError)
    trackableError.errorCodeRef = errorCodeRef
    return trackableError
  }

  return trackableError
}

function isDetailedError(errorResponse: ServiceError<ErrorResponse>): boolean {
  if (
    errorResponse?.data?.errors &&
    errorResponse.isPresentable &&
    errorResponse.data.errors[0].code &&
    errorResponse.data.errors[0].message
  ) {
    return true
  }
  return false
}

function handlingError(
  error: ServiceError<ErrorResponse> | Error,
  handleError: (error: unknown) => void,
  errorCodeRef: string,
): void {
  unstable_batchedUpdates(() => {
    const errorInstance = withErrorCodeRef(error, errorCodeRef)
    useBoundState.getState().setIsLoading(false)
    useBoundState.getState().resetError()
    if (error instanceof ServiceError) {
      const isDetailed = isDetailedError(error)
      useBoundState.getState().setIsDetailedError(isDetailed)
      if (isDetailed) {
        useBoundState.getState().setErrorResponse(error.data)
      }
      useBoundState.getState().setErrorInstance(errorInstance)
    }
    useBoundState.getState().setHasError(true)
    handleError(errorInstance)
  })
}

async function errorHandlerDecorator(
  callback: () => Promise<unknown>,
  handleError: (error: unknown) => void,
  errorCodeRef: string,
): Promise<void> {
  try {
    await callback()
  } catch (e) {
    handlingError(e as Error, handleError, errorCodeRef)
  }
}

export { errorHandlerDecorator as _try, withErrorCodeRef }
