import { IneligibilityResponse } from '../../module/family/pages/ineligibility/types'

export type IneligibilityState = {
  ineligibility: IneligibilityObject
} & IneligibilityAction

export interface IneligibilityObject {
  ineligibilityResponse: IneligibilityResponse
}

export interface IneligibilityAction {
  setIneligibilityResponsed: (ineligibilityResponse: IneligibilityResponse) => void
}

export const INITIAL_STATE: IneligibilityObject = {
  ineligibilityResponse: {
    code: 0,
    pageTitle: '',
    icon: '',
    title: '',
    descriptions: [],
    footer: {
      primaryButton: '',
    },
  },
}
