import { ReceiptResponse } from '../../module/family/pages/receipt/types'

export type ReceiptState = {
  receipt: ReceiptObject
} & ReceiptAction

export interface ReceiptObject {
  receipt: ReceiptResponse
}

export interface ReceiptAction {
  setReceipt: (receipt: ReceiptResponse) => void
}

export const INITIAL_STATE: ReceiptObject = {
  receipt: {
    code: 0,
    pageTitle: '',
    icon: '',
    title: '',
    subTitle: '',
    planDetails: {
      title: '',
      details: [],
    },
    crossSelling: {
      title: '',
      bannerUrl: '',
    },
    footer: {
      primaryButton: '',
      secondaryButton: '',
    },
  },
}
