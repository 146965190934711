import React, { useEffect } from 'react'

import { useLocation } from 'wouter'
import { ErrorBoundary } from 'react-error-boundary'

import { initTheme, Theme } from '@interco/inter-ui'

import RoutesComponent from './routes'
import useBoundState from './store'
import { WouterService } from './common/service/wouterService'
import { ErrorFallback, ErrorObserver } from './common/components'
import { MonitoringService } from './common/service/monitoringService'
import Overlay from './common/components/overlay'
import OnWebviewInit from './common/components/onWebviewInit'
import BridgeService from './common/service/bridgeService'
import { useLink } from './common/hooks'

function App() {
  const addLink = useLink()
  const setLocation = useLocation()[1]
  const resetErrorState = useBoundState((state) => state.resetError)

  const { isDarkMode } = useBoundState((state) => state.darkMode)

  WouterService.initWouter(setLocation)

  useEffect(() => {
    addLink('https://cdn.bancointer.com.br/inter-ui/4.x/latest/inter-ui.css')
    addLink('https://fonts.googleapis.com/css?family=Inter&display=swap')
    addLink('https://fonts.googleapis.com/css?family=Sora&display=swap')

    initTheme(Theme.PF, isDarkMode)
    BridgeService.editToolBar()
  })

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => resetErrorState}
      onError={(error, info) =>
        MonitoringService.noticeError(error, {
          componentStackTrace: String(info.componentStack),
        })
      }
    >
      <Overlay />
      <OnWebviewInit />
      <RoutesComponent />
      <ErrorObserver />
    </ErrorBoundary>
  )
}

export default App
