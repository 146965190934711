import { IWbISafeResponse, IWbUserInfo } from '@interco/inter-webview-bridge'

import { EnvVariableService } from '../../service/envService'
import { IRequestISafe } from '../../service/bridgeService/types'
import { MonitoringService } from '../../service/monitoringService'
import { EnvVariableKeys } from '../../service/envService/types'

const INTER_ENV = EnvVariableService.getVariable(EnvVariableKeys.INTER_ENV)

export function logISafeRequest(request: IRequestISafe): void {
  MonitoringService.log('BridgeService.getISafeToken.request', request)
}

export function logISafeResponse(response: IWbISafeResponse): void {
  if (INTER_ENV === 'production') {
    MonitoringService.log('BridgeService.getISafeToken.response', {
      result: 'SUCCESS',
    })
  } else {
    MonitoringService.log('BridgeService.getISafeToken.response', response)
  }
}

export function logUserInfoResponse(response: IWbUserInfo): void {
  if (INTER_ENV === 'production') {
    MonitoringService.log('BridgeService.getUserInfo.response', {
      result: 'SUCCESS',
    })
  } else {
    MonitoringService.log('BridgeService.getUserInfo.response', response)
  }
}
