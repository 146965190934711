import { StateCreator } from 'zustand'

import { INITIAL_STATE, IneligibilityState } from './types'

const createIneligibilityResponsedSlice: StateCreator<
  IneligibilityState,
  [],
  [],
  IneligibilityState
> = (set) => ({
  ineligibility: INITIAL_STATE,
  setIneligibilityResponsed: (ineligibilityResponse) =>
    set(() => ({ ineligibility: { ineligibilityResponse } })),
})
export default createIneligibilityResponsedSlice
