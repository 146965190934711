import { Skeleton } from '@interco/inter-ui/components/Skeleton'

const ShimmerHome = () => (
  <div style={{ padding: '24px' }}>
    <Skeleton width="100%" height="24px" />
    <Skeleton width="75%" height="24px" style={{ marginBottom: 24 }} />
    <Skeleton width="100%" height="404px" style={{ marginBottom: 16 }} />
    <Skeleton width="100%" height="24px" style={{ marginBottom: 24 }} />
    <Skeleton width="100%" height="84px" />
  </div>
)

export default ShimmerHome
