import React from 'react'

import { Switch } from 'wouter'

import ModulesRoutes from '../module'
import TransitionManager from '../common/components/transitionManager'
import ShimmerHome from '../module/family/pages/home/shimmerHome'

const NavBar = React.lazy(() => import('../common/components/navbar'))
const BottomSheet = React.lazy(() => import('../common/components/bottomSheet'))

const RoutesComponent = () => (
  <React.Suspense fallback={<ShimmerHome />}>
    <TransitionManager>
      <NavBar />
      <Switch>
        <ModulesRoutes />
      </Switch>
    </TransitionManager>
    <BottomSheet />
  </React.Suspense>
)

export default RoutesComponent
