import { useCallback, useMemo } from 'react'

import { useErrorHandler } from 'react-error-boundary'

import { Signal } from '@interco/inter-ui/components/Signal'
import Attention from '@interco/icons/orangeds/MD/attention'
import Canceled from '@interco/icons/orangeds/MD/canceled'

import BridgeService from '../../../service/bridgeService'
import { IArrowLeftState, IButtonState, IUseErrorFallbackConfig } from './types'
import useBoundState from '../../../../store'
import { _try } from '../../../utils/errorUtils'

export function useErrorFallbackConfig(): IUseErrorFallbackConfig {
  const { isDetailedError } = useBoundState((state) => state.error)
  const handlerError = useErrorHandler()

  const backToNative = useCallback(() => {
    _try(
      async () => {
        await BridgeService.backToNative()
      },
      handlerError,
      'ErrorFallback.BackToNative',
    )
  }, [handlerError])

  const signalHandler: React.ReactElement = useMemo(() => {
    if (isDetailedError) {
      return <Signal icon={Attention} theme="warning" intensity="dark" />
    }
    return <Signal icon={Canceled} theme="error" intensity="dark" />
  }, [isDetailedError])

  const buttonStateHandler: IButtonState = {
    label: 'Voltar para seguros',
    onClick: () => backToNative(),
  }

  const arrowLeftStateHandler: IArrowLeftState = {
    onClick: () => backToNative(),
  }

  return {
    arrowLeftState: arrowLeftStateHandler,
    signalState: signalHandler,
    buttonState: buttonStateHandler,
  }
}
